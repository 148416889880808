import { Builder, builder } from "@builder.io/sdk";
import { BuilderStatus } from "./types";
import { BUILDER_CONFIG } from "./builder.config";

/**
 * Get the status of builder. This only works on the client side
 *
 * isPreviewing: True on visual editor and current draft
 *
 * isEditing: True on visual editor
 *
 * @returns {BuilderStatus}
 */
export const getBuilderStatus = (): BuilderStatus => {
	return {
		isPreviewing: Builder.isPreviewing,
		isEditing: Builder.isEditing,
	};
};

/**
 * Initial builder with the specified api key matching store
 * @param store to get the api key
 * @returns builder config for space
 */
export const initBuilder = () => {
	// Builder Public API Key set in .env file
	builder.init(BUILDER_CONFIG.publicKey);
};
