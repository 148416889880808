import { useEffect, useState } from "react";
import { initBuilder } from "@/lib/builder/builder-utils";
import { builder } from "@builder.io/sdk";

type CorporateSettings = {
	newsletterSignUpLink: string;
};
export const useCorporateSettings = () => {
	initBuilder();
	const [corpSettings, setCorpSettings] = useState<CorporateSettings | null>(
		null,
	);

	useEffect(() => {
		const fetchCorpSettings = async () => {
			try {
				const response = await builder
					.get("corp-site-general-settings", {
						options: { noTargeting: true },
					})
					.promise();
				setCorpSettings(response.data);
			} catch (error) {
				console.error("Error fetching corp settings:", error);
			}
		};

		fetchCorpSettings();
	}, []);

	return corpSettings;
};
