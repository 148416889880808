"use client";

import { WebstoreSettings } from "@/lib/5874/types";
import { createContext, useContext } from "react";

interface ContextProps extends WebstoreSettings {}

interface Props {
	content: WebstoreSettings;
	children: React.ReactNode;
}

const Context = createContext({} as ContextProps);

export const StoreSettingsProvider = ({ content, children }: Props) => {
	const value = {
		...content,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useStoreSettingsContext = () => {
	const context = useContext(Context);

	if (!context) {
		throw new Error(
			"useStoreSettingsContext must be used within a StoreSettingsProvider",
		);
	}

	return context;
};
